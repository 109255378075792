.SelectLabel {
  width: 100%;
  height: 52px;
  border-radius: 999px;
  border: 1px solid $gray;

  display: block;

  &__container {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;

    position: relative;
    z-index: 0;

    span {
      @include font(14, 400);
      color: $blackLight;
      background-color: $white;
      padding: 0 10px;
      border-radius: 999px;
      margin-top: -53px;
      transition: all ease .5s;
      cursor: text;
  
      position: absolute;
      left: 20px;
    }
  
    select {
      width: 100%;
      height: 100%;
      border: 0;
      outline: 0;
      padding: 0 30px;
      border-radius: 999px;
      background-color: transparent;
      background-size: 16px;
      background-position: 95.5% 50%;
      background-repeat: no-repeat;
      -webkit-appearance:none; 
      @include font(12, 400);

      @include responsive($mobileBig) {
        background-position: 93% 50%;
      }
    }
  }
}