.FormPagesPages {
  &__inputs {
    margin-bottom: 37px;

    &--duo {
      margin-bottom: 14px;

      display: flex;

      @include responsive($tablet) {
        flex-direction: column;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .SelectDuo {
        select {
          background-position: 98% 50%;

          @include responsive($tablet) {
            background-position: 95% 50%;
          }
        }
      }

      .InputDuo {
        &:first-child {
          padding-right: 42px;

          @include responsive($tablet) {
            padding-right: 0;
            margin-bottom: 14px;
          }
        }
      }
    }
  }

  &__content {
    margin-bottom: 29px;
  }

  &__button {
    width: 50%;
    padding-right: 32px;
    margin-bottom: 36px;

    @include responsive($tablet) {
      width: 100%;
      padding-right: 0;
    }
  }
}