.Item {
  .Tabs {
    width: 100%;

    &__header {
      display: flex;

      &--list {
        max-width: calc(100% - 58px);
        overflow-x: auto;

        display: flex;

        .TabsOptions {
          padding: 15px 19px 18px 15px;
          height: 100%;
          border: 1px solid $gray;
          border-right: 0;
          border-bottom: 0;
          cursor: pointer;

          p {
            @include font(14, 400, 16, $grayDark);
            white-space: nowrap;
          }

          &.active-option {
            border-bottom: 3px solid $white;
          }
        }
      }

      &--add {
        padding: 0 19px 0 15px;
        border: 1px solid $gray;
        border-bottom: 0;
        font-size: 23px;
        color: $grayDark;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__body {
      margin-top: -2px;
      border: 1px solid $gray;

      &--content {
        padding: 20px;

        .Inputs {
          margin-bottom: 20px;

          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          @include responsive($tablet) {
            flex-direction: column;
          }

          label,
          >div {
            flex: 1;
            min-width: calc(50% - 10px);

            @include responsive($tablet) {
              flex: unset;
            }

            &.GroupFiles,
            &.EditorWorld,
            &.GroupTexts {
              min-width: 100%;
            }
          }

          .FileLabel {
            max-width: 100%;
          }

          .SelectFile {
            width: 100%;
            height: 52px;
            border-radius: 999px;
            border: 1px solid $gray;
            cursor: pointer;
            
            display: flex;
            justify-content: space-between;
            align-items: center;

            position: relative;

            &.active-file {
              p {
                margin-top: -55px
              }
            }

            p {
              @include font(14, 400, 16, $blackLight);
              padding: 0 10px;
              background-color: $white;
              transition: all ease .5s;
              
              position: absolute;
              left: 20px;
            }

            img {
              width: 52px;
              height: 52px;
              border-radius: 50%;
              padding: 5px;
              border: 1px solid $gray;

              object-fit: cover;
              object-position: center;
            }

            span {
              max-width: 350px;
              width: 25vw;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding: 0 20px;
              @include font(12, 400, 14);

              @include responsive($mobileBig) {
                width: 50vw;
              }
            }
          }

          .GroupTexts,
          .GroupFiles {
            border: 1px solid $gray;
            padding: 20px;
            border-radius: 20px;

            td {
              padding: 10px 0;

              button {
                font-size: 24px;
                margin-right: 10px; 
                color: $secondary;
                cursor: pointer;
  
                &:last-child {
                  margin-right: 0;
                  color: $red;
                }
              }
            }
          }
        }

        .Buttons {
          display: flex;

          @include responsive($tablet) {
            flex-direction: column;
          }

          .Button {
            margin-right: 40px;
            max-width: 100%;

            @include responsive($mobileBig) {
              margin-right: 20px;
            }

            @include responsive($tablet) {
              margin-bottom: 20px;
            }

            &:last-child {
              border-color: $red;
              color: $red;
              margin-right: 0;

              @include responsive($tablet) {
                margin-bottom: 0;
              }

              &:hover {
                background-color: $red;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}