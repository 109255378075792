.FormGroups {
  &__inputs {
    margin: 20px 0;
  }

  &__abas {
    margin-bottom: 20px;

    &--title {
      margin-bottom: 10px;

      display: block;
    }

    .CheckboxLabel {
      margin-bottom: 10px;
    }

    &--list {
      padding-left: 20px;

      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @include responsive($mobileBig) {
        grid-template-columns: 1fr;
      }
    }
  }

  &__button {
    padding: 0;
    width: 100%;
    display:flex;
    justify-content:center;
    gap: 10px;

    &.grid-groups {
      grid-template-columns: 1fr 1fr;
      gap: 42px;

      @include responsive($tablet) {
        grid-template-columns: 1fr;
        gap: 14px;
      }
    }

    @include responsive($tablet) {
      padding-right: 0;
    }
  }

  &__list {
    margin: 20px 0;
  }
}