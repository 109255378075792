.Footer {
  grid-area: FooterLayout;

  &__container {
    padding: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    p {
      @include font(14, 400);
    }
  }
}