.BarInfo {
  grid-area: BarInfoLayout;
  z-index: 2;

  &__container {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    display: flex;
    justify-content: space-between;

    @include responsive($tablet) {
      flex-direction: column;
      padding: 0;
      padding-top: 70px;
    }

    .Info {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      @include responsive($tablet) {
        padding: 0 16px;
        order: 1;
      }

      &__tokens {
        margin-right: 16px;
      
        @include responsive($tablet) {
          flex: 1;
        }

        a {
          color: $primary;
          text-decoration: underline;
        }

        h2 {
          width: 100%;
          @include font(14, 500);
          color: $primary;

          display: flex;
          align-items: center;

          span {
            margin-left: 4px;
            font-size: 18px;
            cursor: pointer;
          }

        }

        & .TextField span {
          white-space: nowrap;
        }
      }

      &__social {
        @include responsive($tablet) {
          flex: 1
        }
        
        h2 {
          @include font(14, 500);
          color: $primary;
        }

        p {
          @include font(12, 400);
          color: $primary;
        }
      }
    }

    .Options {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include responsive($tablet) {
        width: 100%;
        height: 50px;
        padding: 0 16px;
        background-color: $primary;
        margin-bottom: 5px;

        order: 0;
        display: flex;
        align-items: center;

        position: fixed;
        top: 0;
        left: 0;
      }

      &__logo {
        display: none;

        @include responsive($tablet) {
          display: block;

          img {
            width: 103px;
          }
        }
      }

      &__Select{
        display:flex;
        flex-direction: row;
        gap:16px;
        align-items: center;
        justify-content: space-between;

        @include responsive($tablet) {
          margin-left:4px;
          gap:4px;
        }

        label{
          min-width: 125px;
          height: 52px;

          @include responsive($tablet) {
            height: 100%;
          }
        }

        select{
          color:$black;
          
          @include responsive($tablet) {
            color:$white;
            padding: 10px 28px 10px 8px ;
          }
        }

        option {
          color: $black;
        }
      }

      &__image {
        margin-right: 24px;

        @include responsive($tablet) {
          margin-right: 0px;
          
          flex: 1;
          display: flex;
          justify-content: flex-end;
        }

        p {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 2px solid $primary;
          @include font(22, 400, 22, $primary);
          font-family: 'Paytone One';

          display: flex;
          justify-content: center;
          align-items: center;

          @include responsive($tablet) {
            width: 32px;
            height: 32px;
            @include font(14, 400, 22, $white);
            border-color: $white;
          }
        }
      }

      &__button {
        position: relative;

        @include responsive($tablet) {
          margin-right: -8px;
        }

        button {
          margin: 0;
          padding: 0;
          outline: 0;
          border: 0;
          font-size: 40px;
          color: $primary;
          background-color: transparent;
          cursor: pointer;

          display: flex;
          align-items: center;

          @include responsive($tablet) {
            font-size: 30px;
            color: $white;
          }
        }

        &--box {
          padding-top: 20px;
          width: 290px;
          margin-top: -20px;
          opacity: 0;
          pointer-events: none;
          transition: all ease .5s;

          position: absolute;
          top: 100%;
          right: 0;

          &.active-box {
            margin-top: 0; 
            pointer-events: all;
            opacity: 1;
          }

          @include responsive($tablet) {
            width: 100%;
            height: 100vh;
            margin-top: 0 !important;
            background-color: rgba(0, 0, 0, .4);

            display: flex;
            align-items: flex-end;

            position: fixed;
            top: 0;
            left: 0;
            right: unset;

            &.active-box {
              opacity: 1 !important;
              pointer-events: all !important;

              .ToolBox {
                margin-bottom: 0;
              }
            }
          }

          .ToolBox {
            border-radius: 8px;
            background-color: $white;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
            padding: 20px;
            padding-bottom: 74px;

            @include responsive($tablet) {
              width: 100%;
              border-radius: 0;
              border-radius: 8px 8px 0  0;
              margin-bottom: -100%;
              transition: all ease .5s;
            }

            &__top {
              text-align: center;
              margin-bottom: 27px;

              h3 {
                @include font(14, 500);
                color: $primary;
              }
            }

            &__bottom {
              display: flex;
              flex-direction: column;

              a {
                @include font(14, 500);
                color: $primary;
                width: fit-content;
                margin-bottom: 12px;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
