.FormApiGenerateApi {
    &__inputs {
        margin-bottom: 43px;

        &--list {
            padding-left: 35px;
            margin-top: 8px;

            .CheckboxLabel {
                margin-bottom: 8px;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    &__content {
        margin-bottom: 29px;

        .TextField {
            input {
                width: 100%;
                text-overflow: ellipsis;
            }

            span {
                &:nth-child(2) {
                    width: 100%;
                    white-space: nowrap;
                    max-width: 42dvw;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }

        &__button {
            width: 50%;
            padding-right: 38px;

            @include responsive($tablet) {
                width: 100%;
                padding-right: 0;
            }
        }
    }
}
