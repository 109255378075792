.FormBlocksBlock {
  margin-bottom: 22px;

  &__inputs {
    margin-bottom: 20px;

    &--duo {
      margin-bottom: 20px;

      display: flex;

      label {
        margin-right: 40px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  &__button {
    display:flex;
    justify-content:center;

    button {
      margin-right: 40px;

      @include responsive($tablet) {
        margin-bottom: 14px;
      }

      &:last-child {
        margin-right: 0;

        @include responsive($tablet) {
          margin-bottom: 0;
        }
      }
    }
  }
}