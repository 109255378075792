.arrow-container.responsive {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: -8px;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: min-content;
  border-radius: 100px;
  background: #fff;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);

  @include responsive($desktopSmall) {
    display: flex;
  }

  svg {
    width: 24px;
    height: 24px;
    color: $primary;
  }
}

.left.responsive {
    right: unset;
    left: -8px;
    display: none;
    
    @include responsive($desktopSmall) {
        display: flex;
    }
}

.arrow-container {
    position: absolute;
    z-index: 1;
    top: 45%;
    right: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    width: min-content;
    border-radius: 100px;
    background: #fff;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
  
    svg {
      width: 24px;
      height: 24px;
      color: $primary;
    }
  }

.left {
  right: unset;
  left: -8px;
  display: flex;
}
