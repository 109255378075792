.Canceled {
  &__invoices {
    margin-bottom: 73px;
  }

  &__results {
    .TableArea__custom {
      @include responsive($tablet) {
        display: none;
      }
    }

    table {
      @include responsive($tablet) {
        display: inline-table;
      }

      tr {
        &:first-child {
          td {
            &:first-child {
              text-align: left;
            }
          }
        }
  
        td {
          &:first-child {
            text-align: right;
          }
  
          &:last-child {
            text-align: right;
          }
        }
  
        th {
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
}