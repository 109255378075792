.FormBlocksContent {
  &__header {
    margin-bottom: 40px;
    padding-bottom: 10px;
    border-bottom: 2px solid $secondary;
  }

  &__add {
    margin-bottom: 40px;

    display: flex;

    @include responsive($mobileBig) {
      flex-direction: column;
    }

    .SelectLabel {
      width: 75%;
      margin-right: 20px;

      @include responsive($mobileBig) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    button {
      width: 25%;

      @include responsive($mobileBig) {
        width: 100%;
      }
    }
  }

  &__inputsProps {
    label {
      margin-bottom: 20px;
    }
  }

  &__buttons {
    margin-bottom: 20px;
    display: flex;
    gap: 16px;
    justify-content: center;

    @include responsive($tablet) {
      flex-direction: column;
    }

    .Button {
      &:last-child {
        margin: 0;
      }
    }
  }

  &__inputs {
    .TableArea {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}