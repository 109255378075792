.Pending {
  &__invoices {
    margin-bottom: 73px;
  }

  &__results {
    .TableArea__custom {
      @include responsive($tablet) {
        display: none;
      }
    }

    table {
      @include responsive($tablet) {
        display: inline-table;
      }

      tr {
        &:first-child {
          td {
            &:first-child {
              text-align: left;
            }
          }
        }
  
        td {
          &:first-child {
            text-align: right;
          }
  
          &:last-child {
            text-align: right;
          }
        }
  
        th {
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  div.payment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;

    img {
      max-width: 250px;
    }

    div.TextField {
      max-width: 250px;
      width: 100%;

      span {
        display: none;
      }

      button {
        width: 100%;
        padding: 15px;
        background-color:$primary;
        border-radius: 10px;
        color: $white;
        font-size: 20px;
      }
    }
  }
}