$white: #FFF;
$gray: #CCC;
$black: #000;
$red: #FF0000;

$blackLight: #212121;
$grayLight: #F3F5F6;
$grayDark: #777777;
$whiteDark: #F5F5F5;

$whiteDarkVariant: #F9F9F9;

$primary: #19376E;
$secondary: #00A1E1;
$third: #3BA4C6;
$quaternary: #19375E;
$fifth: #07154B;