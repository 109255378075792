.WelcomeContainer {
  width: 100%;
  
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    max-width: 501px;
    padding: 36px 32px 45px 32px;
    border-radius: 20px;
    background-color: $white;

    @include responsive($mobileBig) {
      padding: 36px 24px 45px 24px;
    }

    &--title {
      margin-bottom: 15px;

      h1 {
        @include font(34, 400, 47, $primary);
        font-family: 'Paytone One';

        @include responsive($mobileBig) {
          @include font(28, 400, 47, $primary);
        }
      }
    }

    &--text {
      max-width: 423px;
      margin-bottom: 32px;

      @include responsive($tablet) {
        margin-bottom: 32px;
      }

      p {
        @include font(16, 400, 19, $primary);
      }
    }

    &--buttons {
      button {
          margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}