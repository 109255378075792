.FormMailMarketing {
  &__inputs {
    margin-bottom: 20px;
  }

  &__buttons {
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    gap: 16px;

    @include responsive($tablet) {
      flex-direction: column;
    }

    label {
      min-width: 150px;
      padding: 17px 20px;
      border-radius: 999px;
      background-color: transparent;
      border: 1px solid $secondary;
      color: $secondary;
      transition: all ease 0.5s;
      cursor: pointer;

      display: flex;
      justify-content: center;

      &:hover {
        background-color: transparent;
        border-color: $primary;
        color: $primary;
      }

      input {
        display: none;
      }

      @include responsive($mobileBig) {
        min-width: 100%;
      }
    }

    button {
      max-width: 100%;
      min-width: 150px;
      padding: 16px 16px;
      margin: 0 !important;

      @include responsive($mobileBig) {
        min-width: 100%;

        &.Button--outlined {
          flex: 1;
          white-space: nowrap;
          max-width: unset  !important;
          min-width: calc(50% - 20px);
        }
      }
    }
  }
}