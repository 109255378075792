.FormUserProfile {
  .FormUserUser__inputs {
    .FormUserUser__inputs--duo {
      &:nth-child(2) {
        .InputLabel {
          padding: 0;
        }
      }
    }
  }
}