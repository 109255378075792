.FormAccountRegister {
  &__inputs {
    margin-bottom: 25px;

    .SelectLabel {
      margin-bottom: 25px;
    }

    &--duo {
      display: flex;
      margin-bottom: 25px;

      @include responsive($tablet) {
        flex-direction: column;
      }

      .InputDuo {
        &:first-child {
          padding-right: 5px;
        }

        &:last-child {
          padding-left: 5px;
        }

        @include responsive($tablet) {
          padding: 0;
          margin-bottom: 25px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    &--control {
      margin-bottom: 25px;
    }
  }

  &__terms-accept{
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;

    p {
      @include font(16, 400);

      a {
        @include font(16, 400);
        font-weight: 600;
        color: $primary;
        cursor: pointer;
      }
    }
  }

  &__change-page {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    div {
      text-align: center;

      p {
        @include font(16, 400);

        span {
          font-weight: 600;
          color: $primary;
          cursor: pointer;
        }
      }
    }
  }
}
