.NavItem {
  padding: 8px 20px;
  margin-bottom: 24px;
  transition: all ease .5s;

  &.active-nav {
    background-color: $fifth;
  }

  @include responsive($tablet) {
    margin-bottom: 48px;
    opacity: .7;
    padding: 0;
    
    &.active-nav {
      background-color: transparent;
      opacity: 1;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 25px;
      color: $white; 

      @include responsive($tablet) {
        color: $primary
      }
    }

    p {
      @include font(14, 400);
      color: $white;
      text-align: center;

      @include responsive($tablet) {
        color: $primary;
      }
    }
  }
}