.FormWebstores {
  &__inputs {
    margin-bottom: 37px;

    &--duo {
      margin-bottom: 14px;

      display: flex;

      @include responsive($tablet) {
        flex-direction: column;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .InputDuo {
        &:first-child {
          padding-right: 42px;

          @include responsive($tablet) {
            padding-right: 0;
            margin-bottom: 14px;
          }
        }
      }
    }
  }

  &__content {
    margin-bottom: 29px;
  }

  .FormWebstores__userview {
    display: flex;

    &--duo {
      flex: 1;

      &:first-child {
        padding-right: 42px;
      }
    }
  }

  .FormWebstores__userview--list{
    display: flex;
    gap: 10px;

    button {
      position: relative;
      padding: 8px;

      .removeUser {
        cursor: pointer;
        position: absolute;
        top: -5px;
        right: 0;
        padding: 5px;
        font-size: 12px;
        background-color: red;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease .5s;

        &:hover {
          background-color: rgb(250, 96, 96);
        }
      }
    }
  }

  &__button {
    padding: 0;
    width: 100%;
    display:flex;
    justify-content:center;

    &.grid-button {
      grid-template-columns: 1fr 1fr;
      gap: 42px;

      @include responsive($tablet) {
        grid-template-columns: 1fr;
        gap: 14px;
      }
    }

    @include responsive($tablet) {
      padding-right: 0;
    }
  }

  &__list {
    margin: 20px 0;
  }
}