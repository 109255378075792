.loading-container {
    position: fixed;
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    z-index: 100;

  .loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;

    .dot-1,
    .dot-2 {
      width: 20px;
      height: 20px;
      background-color: $secondary;
      border-radius: 50%;
      margin: 0 5px;
    }

    .dot-1 {
      animation: bounce1 1s ease-in-out infinite;
    }

    .dot-2 {
      animation: bounce2 1s ease-in-out infinite;
    }
  }
}

@keyframes bounce1 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(
      -20px
    ); /* Adjust the distance dots travel up and down */
  }
}

@keyframes bounce2 {
  0%,
  100% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0);
  }
}
