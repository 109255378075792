.Main {
  grid-area: MainLayout;

  &__container {
    a {
      color: $primary;
      margin: 16px 0;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }

    &--modal {
      .select {
        width: 100%;
        padding: 10px;
        font-size: 16px;
      }

      .plan-selected {
        margin-bottom: 24px;
        .title {
          padding: 20px;
          text-align: center;
          font-size: 20px;
          text-transform: uppercase;
        }

        .periods {
          position: relative;
          .period {
            padding: 8px;
            display: flex !important;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .Button {
        margin: 0;
        width: 100%;
        max-width: unset;
        margin-top: 10px;
      }

      .payment {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          margin-top: 20px;
          letter-spacing: 1.5px;
          text-align: center;
        }

        h4 {
          font-size: 20px;
        }

        img {
          max-width: 320px;
        }

        .TextField {
          box-sizing: border-box;
          flex-direction: column;
          width: 100%;

          span {
            word-break: break-all;
            white-space: normal;

            &:last-child {
              width: 100%;
              word-wrap: break-word;
              text-align: center;
            }
          }

          button {
            width: 100%;
            padding: 10px;
            background-color: #00A1E1;
            margin-top: 10px;
            color: #fff;
            font-size: 20px;
            border-radius: 25px;
          }
        }
      }
    }
  }
}