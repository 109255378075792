.AccountRecover {
    &__content {
        padding: 35px;

        .AccountRecover__data {
            .InputLabel {
                margin-bottom: 10px;
            }
        }

        .WelcomeContainer {
            &__content { 
                padding: 35px;

                &--title {
                    h1 {
                        text-align: center;
                    }
                }
            }
        }
    }
}