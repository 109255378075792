.RegisterContainer {
  flex: 1;
  
  display: flex;
  justify-content: center;
  align-items: center;

  @include responsive($tablet) {
    padding: 0 16px;
  }

  &__content {
    max-width: 501px;
    width: 100%;
    padding: 31px 39px 36px;
    background-color: $white;
    border-radius: 20px;

    @include responsive($mobileBig) {
      padding: 31px 24px 36px;
    }
  
    &--title {
      margin-bottom: 25px;

      h1 {
        @include font(34, 400, 47, $primary);
        font-family: 'Paytone One';

        @include responsive($mobileBig) {
          @include font(28, 400, 47, $primary);
        }
      }
    }
  }
}