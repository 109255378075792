.FormMenuMenu {
  &__inputs {
    margin-bottom: 37px;

    &--duo {
      margin-bottom: 14px;

      display: flex;

      @include responsive($tablet) {
        flex-direction: column;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .InputDuo {
        &:first-child {
          padding-right: 42px;

          @include responsive($tablet) {
            padding-right: 0;
            margin-bottom: 14px;
          }
        }

        &:last-child {
          padding-right: 0 !important;
        }
      }
    }
  }

  &__content {
    margin: 20px 0;
  }

  &__button {
    padding: 0;
    width: 100%;
    display:flex;
    justify-content:center;
    gap: 64px;

    &.grid-button {
      grid-template-columns: 1fr 1fr;
      gap: 42px;

      @include responsive($tablet) {
        grid-template-columns: 1fr;
        gap: 14px;
      }
    }

    @include responsive($tablet) {
      width: 100%;
      padding-right: 0;
    }
  }
}