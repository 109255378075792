.GridArea {
  width: 100%;

  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  @include responsive($mobileBig) {
    grid-template-columns: 1fr;
  }

  .colspan-2,
  .EditorWorld {
    grid-column: span 2;

    @include responsive($mobileBig) {
      width: 99%;
      grid-column: span 1;
    }
  }
}