.plans-area {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    min-height: 100vh;
    max-width: 100%;
    background-color: $whiteDark;

    .select-plans-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        border-radius: 20px;
        gap: 32px;
        margin: 16px;

        @include responsive($desktopSmall) {
            max-width: calc(100% - 32px);
        }

        @include responsive($mobileBig) {
            padding: 24px 16px;
        }

        .select-plans-change-page {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;

            p {
                @include font(16, 400);

                span {
                    font-weight: 600;
                    color: $primary;
                    cursor: pointer;
                }
            }
        }

        .select-plans-title {
            color: $primary;
            font-weight: 700;
            @include font(34, 400, 47, $primary);
            font-family: "Paytone One";

            @include responsive($tablet) {
                @include font(28, 400, 47, $primary);
            }

            @include responsive($mobileSmall) {
                @include font(24, 400, 47, $primary);
            }
        }
    }
}
