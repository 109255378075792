.FormHomeMyData {
  &__inputs {
    margin-bottom: 25px;

    &--duo {
      display: flex;
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      .InputDuo {
        &:first-child {
          padding-right: 49px;
        }

        &:last-child {
          padding: 0;
        }

        @include responsive($tablet) {
          padding: 0 !important;
          margin-bottom: 14px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      @include responsive($tablet) {
        flex-direction: column;
      }
    }
  }

  &__button {
    width: 50%;
    padding-right: 35px;

    @include responsive($tablet) {
      width: 100%;
      padding-right: 0;
    }
  }
}