.ContactView {
    &__modal {
        .Modal__content--body {

            .Button{
                max-width: 100%;
            }

            button,
            .EditorWorld {
                margin-top: 10px;
            }

            .EditorWorld {
                .EditorWorld__buttons {
                    display: none;
                }
            }
        }
    }

    .Box {
        &__content {
            &--button {
                button {
                    max-width: unset;
                    margin: 0;
                }
            }
        }
    }
}