.BlockNav {
  width: 100%;

  &__navigation {
    margin-bottom: 20px;
    background-color: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      overflow-x: auto;

      display: flex;

      li {
        padding: 17px 24px;
        color: $primary;
        transition: all ease .5s;
        cursor: pointer;

        @include responsive($tablet) {
          padding: 17px 12px;
        }

        &.active-block-nav {
          background-color: $whiteDarkVariant;
        }

        &:hover {
          background-color: $whiteDarkVariant;
        }
      }
    }
  }
}