.FormGallery {
  &__inputs {
    .FileLabel {
      max-width: unset;
    }

    &--duo{
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin-bottom: 16px;

      @include responsive($tablet) {
        flex-direction: column;
      }
    }
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 16px;

    @include responsive($tablet) {
      width: 100%;
      padding-right: 0;
      flex-direction: column;
    }
  }

  &__list {
    margin: 20px 0;

    &--image {
      width: 30px;
    }
  }
}